
  import { defineComponent, onMounted, computed, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'customer-statment-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const seleectedInvoice = ref(0);
      const serviceAmount = ref();
      const serviceDiscount = ref();
      const selectedCustomer = ref(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('customerCreditCreating'), [
          translate('finance'),
        ]);
      });

      watch(selectedCustomer, async () => {
        await store.dispatch(Actions.GET_ALL_FINANCE_INVOICE, {
          isPaid: false,
          customerId: selectedCustomer.value,
        });
        seleectedInvoice.value = 0;
      });

      const customerList = await store.dispatch(Actions.GET_ALL_CUSTOMERS);
      const selectedCusotomer = computed(() =>
        customerList.find((customer) => customer.id === selectedCustomer.value)
      );
      const validationSchema = Yup.object().shape({
        customerId: Yup.string()
          .notOneOf(['0'], () => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .required(() => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .label('Customer'),
        invoiceId: Yup.string()
          .notOneOf(['0'], () => translate('INVOICE_IS_REQUIRED_FIELD'))
          .required(() => translate('INVOICE_IS_REQUIRED_FIELD'))
          .label('Invoice'),
        amount: Yup.number()
          .typeError('Please enter a valid number')
          .required('Amount is required')
          .min(0, 'Amount must be greater than or equal to 0')
          .label('amount'),
        discount: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue === '' || originalValue === null) {
              return null;
            }
            return value;
          })
          .min(0, 'Discount must be greater than or equal to 0')
          .max(100, 'Discount cannot exceed 100')
          .nullable(),
      });
      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.amount = Number(values.amount);
        if (values.discount) {
          values.discount = Number(values.discount);
        } else {
          values.discount = 0;
        }
        await store.dispatch(Actions.CREATE_FINANCE_CUSTOMER_CREDIT, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_CUSTOMER_STATMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const calculatedPayment = computed(() => {
        const paymentAfterDiscount =
          serviceAmount.value -
          (serviceAmount.value * serviceDiscount.value) / 100;

        return paymentAfterDiscount;
      });

      return {
        invliceList: computed(() => store.getters.allFinancecInvoice),
        onSubmitCreate,
        customerList,
        selectedCustomer,
        selectedCusotomer,
        seleectedInvoice,
        submitButton,
        serviceAmount,
        serviceDiscount,
        calculatedPayment,
        validationSchema,
        translate,
        goBack,
        can,
      };
    },
  });
